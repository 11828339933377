import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Geolocation, Position } from '@capacitor/geolocation';

@Injectable({
  providedIn: 'root'
})
export class GeolocationService {
  private cache: BehaviorSubject<Position | null> = new BehaviorSubject<Position | null>(null);

  private watchPositionId: string;

  constructor() { }

  /**
   * watchPosition を 開始します.
   */
  async start() {
    this.watchPositionId = await Geolocation.watchPosition(
      {
        enableHighAccuracy: true,
        timeout: 500,
        maximumAge: 0
      }, this.watchPositionCallback);
  }

  /**
   * watchPosition の コールバック.
   * 
   * @param position position
   * @param err エラー
   */
  private watchPositionCallback = (position: Position | null, err?: any) => {
    this.cache.next(position);
  }

  /**
   * watchPosition を停止します.
   */
  async stop() {
    if (this.watchPositionId) {
      await Geolocation.clearWatch({ id: this.watchPositionId });
      this.watchPositionId = null;
    }
  }

  /**
   * watchPosition の Observable を取得します.
   */
  watchPosition(): Observable<Position> {
    return this.cache.asObservable().pipe(filter((position) => position !== null));
  }
}
