import { Paho } from 'ng2-mqtt/mqttws31';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';

import { environment } from '../../../environments/environment';

export class MqttService {
  /** MQTT クライアント. */
  private client: Paho.MQTT.Client;

  /** MQTT接続中. */
  private connecting: boolean = false;

  /** サブスクライブ対象のトピック. */
  private topic: string;

  /** サブスクライブ後の処理. */
  private callback: any;

  constructor() {
  }

  setSubscribe(topic: string, callback: any) {
    this.topic = topic;
    this.callback = callback;
  }

  setConnection() {
    if (this.connecting) {
      // 接続中の場合、処理を終了
      return;
    }

    this.connecting = true;

    // 鍵ファイルを読込
    var regionName = environment.setting.mqttKey.regionName;
    var awsIotEndpoint = environment.setting.mqttKey.awsIotEndPoint;
    var accessKey = environment.setting.mqttKey.accessKey;
    var secretKey = environment.setting.mqttKey.secretKey;
    var endpoint = this.createEndpoint(
      regionName, // Your Region
      awsIotEndpoint, // Require 'lowercamelcase'!!
      accessKey,
      secretKey);
    var clientId = Math.random().toString(36).substring(7);
    this.client = new Paho.MQTT.Client(endpoint, clientId);

    this.client.connect(this.getConnectOptions());

    if (this.topic) {
      this.client.onMessageArrived = this.callback;
    }
  }

  /**
   * MQTT接続オプションを取得します.
   *
   * @returns MQTT接続オプション
   */
  private getConnectOptions() {
    return {
      useSSL: true,
      timeout: 30,
      mqttVersion: 4,
      onSuccess: this.connectSuccess,
      onFailure: this.connectFailure
    };
  }

  /**
   * MQTT接続成功後の処理を実行します.
   */
  private connectSuccess = () => {
    this.connecting = false;

    if (this.topic) {
      this.client.subscribe(this.topic, {});
    }
  }

  /**
   * MQTT接続失敗後の処理を実行します.
   */
  private connectFailure = () => {
    this.connecting = false;
  }

  checkConnect() {
    if (!this.client.isConnected()) {
      return false;
    }
    return true;
  }

  connect() {
    this.setConnection();
  }

  disconnect() {
    if (this.client.isConnected()) {
      this.client.disconnect();
    }
  }

  sign(key, msg) {
    var hash = CryptoJS.HmacSHA256(msg, key);
    return hash.toString(CryptoJS.enc.Hex);
  };

  sha256 = function (msg) {
    var hash = CryptoJS.SHA256(msg);
    return hash.toString(CryptoJS.enc.Hex);
  };

  getSignatureKey(key, dateStamp, regionName, serviceName) {
    var kDate = CryptoJS.HmacSHA256(dateStamp, 'AWS4' + key);
    var kRegion = CryptoJS.HmacSHA256(regionName, kDate);
    var kService = CryptoJS.HmacSHA256(serviceName, kRegion);
    var kSigning = CryptoJS.HmacSHA256('aws4_request', kService);
    return kSigning;
  };

  createEndpoint(regionName, awsIotEndpoint, accessKey, secretKey) {
    var time = moment.utc();
    var dateStamp = time.format('YYYYMMDD');
    var amzdate = dateStamp + 'T' + time.format('HHmmss') + 'Z';
    var service = 'iotdevicegateway';
    var region = regionName;
    var secretKey = secretKey;
    var accessKey = accessKey;
    var algorithm = 'AWS4-HMAC-SHA256';
    var method = 'GET';
    var canonicalUri = '/mqtt';
    var host = awsIotEndpoint;

    var credentialScope =
      dateStamp + '/' +
      region + '/' +
      service + '/' +
      'aws4_request';

    var canonicalQuerystring = 'X-Amz-Algorithm=AWS4-HMAC-SHA256';
    canonicalQuerystring +=
      '&X-Amz-Credential=' +
      encodeURIComponent(accessKey + '/' +
        credentialScope);
    canonicalQuerystring += '&X-Amz-Date=' + amzdate;
    canonicalQuerystring += '&X-Amz-SignedHeaders=host';

    var canonicalHeaders = 'host:' + host + '\n';
    var payloadHash = this.sha256('');

    var canonicalRequest =
      method + '\n' +
      canonicalUri + '\n' +
      canonicalQuerystring + '\n' +
      canonicalHeaders + '\nhost\n' +
      payloadHash;

    var stringToSign =
      algorithm + '\n' +
      amzdate + '\n' +
      credentialScope + '\n' +
      this.sha256(canonicalRequest);

    var signingKey = this.getSignatureKey(secretKey, dateStamp, region, service);
    var signature = this.sign(signingKey, stringToSign);

    canonicalQuerystring += '&X-Amz-Signature=' + signature;
    return 'wss://' + host + canonicalUri + '?' + canonicalQuerystring;
  }

  sendTopic(msg: string, topic: string) {
    this.send(msg, topic);
  }

  send(content: any, topic: string) {
    var message = new Paho.MQTT.Message(content);
    message.destinationName = topic;
    console.debug('send :' + content + ', to ' + topic);
    this.client.send(message);
  }
}
