import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

import { Paho } from 'ng2-mqtt/mqttws31';

import { environment } from '../../../environments/environment';

import { TelemetryUpdate } from './models/telemetry-update';
import { MqttPublishService } from './mqtt-publish.service';
import { MqttService } from './mqtt.service';

import { MqttResponse } from './models/mqtt-response';
import { MqttResultType } from './types/mqtt-result-type';

import { SharedSettingService } from '../shared-setting.service';

@Injectable({
  providedIn: 'root'
})
export class TelemetryUpdateMqttService {
  private cache: BehaviorSubject<MqttResponse<TelemetryUpdate> | null> = new BehaviorSubject<MqttResponse<TelemetryUpdate> | null>(null);

  private publishClient: MqttService;
  private subscribeClient: MqttService;

  private topic: string;

  constructor(
    private mqttPublishService: MqttPublishService,
    private sharedSettingService: SharedSettingService
  ) { }

  /**
   * 開始します.
   */
  async start() {
    this.topic = this.sharedSettingService.getVehicle().vin + environment.setting.telemetryUpdateTopic;
    this.publishClient = this.mqttPublishService.getMqttClient();

    if (!this.sharedSettingService.getServicePCFunctionEnabled()) {
      // サービスPC機能がOFFの場合、サブスクライブする
      this.subscribeClient = new MqttService();
      this.subscribeClient.setSubscribe(this.topic, this.onMessageArrived);
      this.subscribeClient.setConnection();
    }
  }

  /**
   * 停止します.
   */
  async stop() {
    this.publishClient = null;

    if (this.subscribeClient) {
      this.subscribeClient.disconnect();
      this.subscribeClient = null;
    }
  }

  /**
   * トピックを取得します.
   */
  getTopic(): string {
    return this.topic;
  }

  /**
   * メッセージを送信します.
   * 
   * @param message 送信メッセージ
   * @returns 送信結果
   */
  publishMessage(message: TelemetryUpdate): boolean {
    if (this.sharedSettingService.getServicePCFunctionEnabled()) {
      // サービスPC機能がONの場合、ローカルで保持しているテレメトリを送信する
      this.cache.next({ result: MqttResultType.SUCCESS, message: message });
    }

    if (this.publishClient?.checkConnect()) {
      this.publishClient.sendTopic(JSON.stringify(this.convertServicePC(message)), this.topic);

      return true;
    }

    return false;
  }

  /**
   * コネクションの状態を確認し、接続が切れている場合、再接続します.
   * 
   * @returns 接続状態
   */
  checkAndRetryConnect(): boolean {
    if (this.sharedSettingService.getServicePCFunctionEnabled()) {
      // サービスPC機能がONの場合、subscribeClientは再接続しない
      return true;
    }

    if (!this.subscribeClient) {
      return false;
    }

    if (this.subscribeClient.checkConnect()) {
      return true;
    }

    this.subscribeClient.setConnection();

    return false;
  }

  /**
   * コールバック.
   */
  onMessageArrived = (message: Paho.MQTT.Message) => {
    try {
      const messageObject = this.convert(message.payloadString);

      this.cache.next({ result: MqttResultType.SUCCESS, message: messageObject });
    } catch (e) {
      console.error(e);

      this.cache.next({ result: MqttResultType.FAIL, message: null });
    }
  }

  /**
   * テレメトリ受信データのデータ型を変換.
   * AD車両は「taxi_status」以外全てstring型のJSONデータをテレメトリとして送信するため、型変換する.
   * @param json JSONデータ
   * @returns 型変換したTelemetryUpdateデータ
   */
  private convert(json: string): TelemetryUpdate{
    const data = JSON.parse(json) as TelemetryUpdate;
    return {
      ad_engaged: Number(data.ad_engaged),
      taxi_status: Number(data.taxi_status),
      vin: String(data.vin),
      create_time: String(data.create_time),
      direction: Number(data.direction),
      gpstime: String(data.gpstime),
      lat: Number(data.lat),
      lng: Number(data.lng),
      speed: Number(data.speed),
      soc: Number(data.soc),
      ac_status: Number(data.ac_status),
      inside_tmp: Number(data.inside_tmp),
      acc_sw: Number(data.acc_sw),
      ign_sw: Number(data.ign_sw),
      odo: Number(data.odo),
      front_left_door_open: Number(data.front_left_door_open),
      front_right_door_open: Number(data.front_right_door_open),
      rear_left_door_open: Number(data.rear_left_door_open),
      rear_right_door_open: Number(data.rear_right_door_open),
      back_door_open: Number(data.back_door_open),
      door_lock: Number(data.door_lock),
      ign_status: Number(data.ign_status),
      torque_req_status: Number(data.torque_req_status),
      shbw_check_warning: Number(data.shbw_check_warning),
      vdc_status: Number(data.vdc_status),
      abs_status: Number(data.abs_status),
      eps_steering_warning: Number(data.eps_steering_warning),
      brake_system_status: Number(data.brake_system_status),
      route_status: Number(data.route_status),
      driver_accel: Number(data.driver_accel),
      driver_brake: Number(data.driver_brake),
      driver_steer: Number(data.driver_steer),
      num_of_dead_nodes: Number(data.num_of_dead_nodes),
      test_oki: Number(data.test_oki),
      name_of_dead_nodes: String(data.name_of_dead_nodes),
      VMCfFAIL_0AF: Number(data.VMCfFAIL_0AF),
      VMCfFAIL_1C2: Number(data.VMCfFAIL_1C2),
      VMCfFAIL_169: Number(data.VMCfFAIL_169),
      VMCfFAIL_016: Number(data.VMCfFAIL_016),
      IDMfFAIL_1C3: Number(data.IDMfFAIL_1C3),
      IDMfFAIL_1C5: Number(data.IDMfFAIL_1C5),
      IDMfFAIL_2B0: Number(data.IDMfFAIL_2B0),
      VSPfFAIL_Latch: Number(data.VSPfFAIL_Latch),
      VSPfFAIL_VSP_now: Number(data.VSPfFAIL_VSP_now),
      EPSfFAIL_5E4: Number(data.EPSfFAIL_5E4),
      EPSfFAIL_300: Number(data.EPSfFAIL_300),
      EPSfFAIL_185_16C: Number(data.EPSfFAIL_185_16C),
      EPSfFAIL_163_16D: Number(data.EPSfFAIL_163_16D),
      ANGLEfFAIL_sensor: Number(data.ANGLEfFAIL_sensor),
      LWSfFAIL_LWS: Number(data.LWSfFAIL_LWS),
      LWSfFAIL_msgcont: Number(data.LWSfFAIL_msgcont),
      BBWfFAIL_FCAAS_ALIVE: Number(data.BBWfFAIL_FCAAS_ALIVE),
      BBWfFAIL_BBWfail: Number(data.BBWfFAIL_BBWfail),
      BBWfFAIL_msgcont: Number(data.BBWfFAIL_msgcont),
      VDCfFAIL_VDC: Number(data.VDCfFAIL_VDC),
      VDCfFAIL_rxstatus: Number(data.VDCfFAIL_rxstatus),
      IDMfFAIL_ICM: Number(data.IDMfFAIL_ICM),
      lb_vol: Number(data.lb_vol),
      lb_current: Number(data.lb_current),
      rnmg: Number(data.rnmg),
      stmg: Number(data.stmg),
      dte: Number(data.dte)
    } as TelemetryUpdate;
  }

  /**
   * テレメトリ送信データのデータ型をサービスPCが送信するテレメトリの型に合わせて変換.
   * AD車両は「taxi_status」以外全てstring型のJSONデータをテレメトリとして送信するため、型変換する.
   * @param data データ
   * @returns 型変換したデータ
   */
   private convertServicePC(data: TelemetryUpdate): any{
    const sendData = {};
    Object.keys(data).forEach((key)=>{
      if(data[key] === null){
        sendData[key] = null;
      }else if(key === "taxi_status"){
        sendData[key] = Number(data[key]);
      }else{
        sendData[key] = String(data[key]);
      }
    });

    return sendData;
  }

  /**
   * Observable を取得します.
   */
  message(): Observable<MqttResponse<TelemetryUpdate>> {
    return this.cache.asObservable().pipe(filter((response) => response !== null));
  }

  /**
   * メッセージを作成します.
   * 
   * @param vin vin
   * @returns メッセージ
   */
  createMessage(vin: string): TelemetryUpdate {
    return {
      ad_engaged: null,
      taxi_status: 1,
      vin: vin,
      create_time: '',
      direction: 0,
      gpstime: '',
      lat: 0.0,
      lng: 0.0,
      speed: null,
      soc: 0,
      ac_status: 0,
      inside_tmp: 0,
      acc_sw: 1,
      ign_sw: 1,
      odo: 0,
      front_left_door_open: 0,
      front_right_door_open: 0,
      rear_left_door_open: 0,
      rear_right_door_open: 0,
      back_door_open: 0,
      door_lock: 0,
      ign_status: 0,
      torque_req_status: 0,
      shbw_check_warning: 0,
      vdc_status: 0,
      abs_status: 0,
      eps_steering_warning: 0,
      brake_system_status: 0,
      route_status: null,
      driver_accel: null,
      driver_brake: null,
      driver_steer: null,
      num_of_dead_nodes: 0,
      test_oki: 1,
      name_of_dead_nodes: '',
      VMCfFAIL_0AF: null,
      VMCfFAIL_1C2: null,
      VMCfFAIL_169: null,
      VMCfFAIL_016: null,
      IDMfFAIL_1C3: null,
      IDMfFAIL_1C5: null,
      IDMfFAIL_2B0: null,
      VSPfFAIL_Latch: null,
      VSPfFAIL_VSP_now: null,
      EPSfFAIL_5E4: null,
      EPSfFAIL_300: null,
      EPSfFAIL_185_16C: null,
      EPSfFAIL_163_16D: null,
      ANGLEfFAIL_sensor: null,
      LWSfFAIL_LWS: null,
      LWSfFAIL_msgcont: null,
      BBWfFAIL_FCAAS_ALIVE: null,
      BBWfFAIL_BBWfail: null,
      BBWfFAIL_msgcont: null,
      VDCfFAIL_VDC: null,
      VDCfFAIL_rxstatus: null,
      IDMfFAIL_ICM: null,
      lb_vol: 0,
      lb_current: -1,
      rnmg: 0,
      stmg: 0,
      dte: 0,
    } as TelemetryUpdate;
  }
}
