/**
 * API結果のタイプ.
 */
 export const ApiResultType = {
  /** 0:失敗. */
  FAIL: 0,
  /** 1:成功. */
  SUCCESS: 1
};

export type ApiResultType = typeof ApiResultType[keyof typeof ApiResultType];
