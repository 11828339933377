import { Injectable } from '@angular/core';

import { NativeAudio } from '@capacitor-community/native-audio';

import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AudioService {
  private routeAssetId = 'route';
  private endChargeAssetId = 'endCharge';
  private endRestAssetId = 'endRest';
  private audioEnabled = environment.setting.audioEnabled;

  constructor() {
    if (this.audioEnabled) {
      // セットルート受信音
      NativeAudio.preload({
        assetId: this.routeAssetId,
        assetPath: 'public/assets/route.mp3',
        audioChannelNum: 1,
        isUrl: false
      });
      // 充電終了音
      NativeAudio.preload({
        assetId: this.endChargeAssetId,
        assetPath: 'public/assets/endCharge.mp3',
        audioChannelNum: 1,
        isUrl: false
      });
      // 休憩終了音
      NativeAudio.preload({
        assetId: this.endRestAssetId,
        assetPath: 'public/assets/endRest.mp3',
        audioChannelNum: 1,
        isUrl: false
      });
    }
  }

  /**
   * セットルート受信音を鳴らす.
   */
  route() {
    if (this.audioEnabled) {
      NativeAudio.play({ assetId: this.routeAssetId, time: 0 });
    }
  }
  /**
   * 充電終了音を鳴らす.
   */
   playEndCharge() {
    if (this.audioEnabled) {
      NativeAudio.play({ assetId: this.endChargeAssetId, time: 0 });
    }
  }

  /**
   * 休憩終了音を鳴らす.
   */
  playEndRest(){
    if (this.audioEnabled) {
      NativeAudio.play({ assetId: this.endRestAssetId, time: 0 });
    }
  }
}
