import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, interval } from 'rxjs';
import { filter, tap, mergeMap } from 'rxjs/operators';

import { environment } from '../../environments/environment';

import { DashboardApiParameter } from './servicer/models/dashboard-api-parameter';
import { DashboardApiResponse } from './servicer/models/dashboard-api-response';
import { VehicleService } from './servicer/vehicle.service';

import { ApiResultType } from './servicer/types/api-result-type';
import { SharedSettingService } from './shared-setting.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardIntervalReceiveService {
  private connectionStatusSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private cache: BehaviorSubject<DashboardApiResponse | null> = new BehaviorSubject<DashboardApiResponse | null>(null);

  /** インターバルのSubscription. */
  private intervalSubscription: Subscription;

  /** パラメータ. */
  private parameter: DashboardApiParameter;

  constructor(
    private sharedSettingService: SharedSettingService,
    private vehicleService: VehicleService
  ) { }

  /**
   * 開始します.
   */
  async start() {
    this.parameter = {
      vin: this.sharedSettingService.getVehicle().vin,
      language: environment.setting.language
    };

    // 初回のダッシュボード取得
    await this.getDashboard();

    let processing = false;

    this.intervalSubscription = interval(environment.setting.interval).pipe(
      filter(() => !processing),
      tap(() => processing = true),
      mergeMap(() => this.getDashboard()),
      tap(() => processing = false)
    ).subscribe();
  }

  /**
   * 停止します.
   */
  async stop() {
    if (this.intervalSubscription) {
      this.intervalSubscription.unsubscribe();
      this.intervalSubscription = null;
    }
  }

  /**
   * ダッシュボードの Observable を取得します.
   */
  dashboard(): Observable<DashboardApiResponse> {
    return this.cache.asObservable().pipe(filter((response) => response !== null));
  }

  /**
   * 接続状態の Observable を取得します.
   */
  connectionStatus(): Observable<boolean> {
    return this.connectionStatusSubject.asObservable();
  }

  /**
   * ダッシュボード表示情報を取得します.
   * 定期的に呼び出されるため、エラーが発生しても、何も処理をしません.
   *
   * @param parameter ダッシュボードパラメータ
   */
  private async getDashboard() {
    try {
      const result = await this.vehicleService.postDashboardRequest(this.parameter).toPromise();

      let connectionStatus = false;

      if (result.result === ApiResultType.SUCCESS) {
        this.cache.next(result);
        connectionStatus = true;
      }

      this.setConnectionStatus(connectionStatus);
    } catch (e) {
      console.error(e);

      this.setConnectionStatus(false);
    }
  }

  /**
   * 接続状態を更新します.
   * 前回と同じ場合、更新しません.
   *
   * @param connectionStatus 接続状態
   */
  private setConnectionStatus(connectionStatus: boolean) {
    if (this.connectionStatusSubject.value !== connectionStatus) {
      this.connectionStatusSubject.next(connectionStatus);
    }
  }
}
