export const environment = {
  production: false,
  setting: {
    /** サービサーURL. */
    servicerApiUrl: 'https://nissan-mslab-reserve-dev.com/vehicle',
    /** AWS IOTの接続情報. */
    mqttKey: {
      /** リージョン名. */
      regionName: 'ap-northeast-1',
      /** エンドポイント名. */
      awsIotEndPoint: 'a15op25gd0w3b6-ats.iot.ap-northeast-1.amazonaws.com',
      /** アクセスキー. */
      accessKey: 'AKIAQ3QEGUIDVN3WTSZ4',
      /** シークレットキー. */
      secretKey: '80Cqpqh4NQVU/lVm+aRWtSQgAe3puzl6/kY17YNB'
    },
    /** オーディオの有効（Webブラウザ上で動作させる場合、無効にすること）. */
    audioEnabled: true,
    /** 到着判断時の閾値. */
    threshold: 20,
    /** ルート情報トピック名. */
    setRouteTopic: '/route/update',
    /** ルート情報受信トピック名. */
    setRouteAcceptedTopic: '/route/update/accepted',
    /** テレメトリ情報トピック名. */
    telemetryUpdateTopic: '/telemetry/update',
    /** ドライバータブレットテレメトリ情報トピック名. */
    telemetryDriverTabletTopic: '/telemetry/driverTablet',
    /** 到着スキップトピック名. */
    arrivedTopic: '/arrived',

    /** MQTT 接続確認とメッセージ送信間隔、ダッシュボードの取得(ミリ秒). */
    interval: 500,
    /**
     * ルート受信後にすぐにルートを削除すると、
     * タイミングによっては削除してほしくないルートまで消えてしまうケースがあるため、
     * 削除しない時間をここで設定する(ミリ秒).
     */
    routeRemoveTime: 5000,
    /**
     * BLE認証時間(ミリ秒).
     */
    bleCertificateTime: 5000,
     /** 停車扱いとする車速(km/h). */
    stopSpeed: 2.5,
    /** 走行中と判定する最低速度(km/h). */
    movingSpeed: 3.0,
    /**
     * 同一目的カウントの上限.
     * 
     * - 「MQTT 接続確認とメッセージ送信間隔」に合わせて、値は変更すること。
     * - デフォルトは3秒（500ミリ秒 × 6回）間隔で、次のタクシーステータスに遷移している。
     */
    waitCountForSameDist: 6,
    /** ルート情報取得時のエラー時のステータスコード. */
    statusDenaError: 400,
    /** ルート情報取得時の正常時のステータスコード. */
    statusDenaAccept: 206,
    /** 車両の方向を取得するかの閾値(m). */
    headingCalcDistance: 1.0,
    /** 緯度経度から平面直角座標変換時の原点. */
    wgs2Htz: {
      /** 平面直角座標xの原点になる緯度. */
      oBasePointLat: 35.4464,
      /** 平面直角座標yの原点になる経度. */
      oBasePointLon: 139.3129
    },
    /** 言語. */
    language: 'ja',
    /** 地図初期表示（緯度）. */
    mapInitialStateLat: 37.496098500,
    /** 地図初期表示（経度）. */
    mapInitialStateLng: 141.000272600,
    /** 地図初期表示（ズーム）. */
    mapInitialStateZoom: 15,
  },

  /** デバッグモード  */
  isDebugMode: false
};
