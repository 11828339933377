/**
 * MQTT結果のタイプ.
 */
 export const MqttResultType = {
  /** 0:失敗. */
  FAIL: 0,
  /** 1:成功. */
  SUCCESS: 1
};

export type MqttResultType = typeof MqttResultType[keyof typeof MqttResultType];
