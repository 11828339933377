import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ArrivedMqttService } from '../services/mqtt/arrived-mqtt.service';
import { MqttConnectionService } from '../services/mqtt/mqtt-connection.service';
import { MqttPublishService } from '../services/mqtt/mqtt-publish.service';
import { RouteUpdateAcceptedMqttService } from '../services/mqtt/route-update-accepted-mqtt.service';
import { RouteUpdateMqttService } from '../services/mqtt/route-update-mqtt.service';
import { TelemetryDriverTabletMqttService } from '../services/mqtt/telemetry-driver-tablet-mqtt.service';
import { TelemetryUpdateMqttService } from '../services/mqtt/telemetry-update-mqtt.service';

import { AudioService } from '../services/native/audio.service';
import { GeolocationService } from '../services/native/geolocation.service';

import { ServicerService } from '../services/servicer/servicer.service';
import { VehicleService } from '../services/servicer/vehicle.service';

import { DashboardIntervalReceiveService } from '../services/dashboard-interval-receive.service';
import { SharedSettingService } from '../services/shared-setting.service';
import { TaxiStatusService } from '../services/taxi-status.service';

@NgModule({
  imports: [
  ],
  declarations: [],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        ArrivedMqttService,
        MqttConnectionService,
        MqttPublishService,
        RouteUpdateAcceptedMqttService,
        RouteUpdateMqttService,
        TelemetryDriverTabletMqttService,
        TelemetryUpdateMqttService,
        AudioService,
        GeolocationService,
        ServicerService,
        VehicleService,
        DashboardIntervalReceiveService,
        SharedSettingService,
        TaxiStatusService
      ]
    };
  }
}
