export class TimeUtil {
  /**
   * 以下の形式の現在日時の文字列を取得します.
   * 
   * yyyy-MM-dd HH:mm:ss.SSS
   *
   * @returns 日付の文字列
   */
  public static getDateString(): string {
    return this.toDateString(new Date());
  }

  /**
   * 以下の形式の現在日時の文字列を取得します.
   * 
   * yyyy-MM-dd HH:mm:ss.SSS
   *
   * @param date 日付
   * @returns 日付の文字列
   */
  public static toDateString(date: Date): string {
    return [
      date.getFullYear(),
      ('0' + (date.getMonth() + 1)).slice(-2),
      ('0' + date.getDate()).slice(-2)
    ].join('-') + ' ' + [
      ('0' + date.getHours()).slice(-2),
      ('0' + date.getMinutes()).slice(-2),
      ('0' + date.getSeconds()).slice(-2),
    ].join(':') + '.' + ('00' + date.getMilliseconds()).slice(-3);
  }

  /**
   * 秒を分に変換します.
   * 
   *
   * @param second 秒
   * @returns 分
   */
  public static secondToMinute(second: number): number {
    return Math.floor(second / 60);
  }

  /**
   * ミリ秒を分に変換します(切り上げ).
   * 
   *
   * @param milliSecond 秒
   * @returns 分
   */
  public static milliToMinute(milliSecond: number): number {
    return Math.ceil(milliSecond / (60 * 1000));
  }

  /**
   * 現在日時にmilliSecondを追加した時分を返す.
   * @param milliSecond 秒
   * @return HH:MM（時分）
   */
  public static addMilliToDateString(milliSecond: number): string{
    const addTime = new Date();
    addTime.setTime(addTime.getTime() + milliSecond);
    const hours = ('0' + addTime.getHours()).slice(-2);
    const minutes = ('0' + addTime.getMinutes()).slice(-2);
    return hours + ':' + minutes;
  }
}
