import { environment } from '../../environments/environment';

export class Wgs2Htz {
  /** 平面直角座標xの原点になる緯度のラジアン */
  oBasePointLatRad: number;
  /** 平面直角座標yの原点になる経度のラジアン */
  oBasePointLonRad: number;
  dC: number;
  dE1: number;
  dE2: number;
  dB1: number;
  dB2: number;
  dB3: number;
  dB4: number;
  dB5: number;
  dB6: number;
  dB7: number;
  dB8: number;
  dB9: number;

  /** 長半径 */
  readonly D_A: number = (6378137.0)
  /** 扁平率 */
  readonly D_F: number = (1.0 / 298.257222101)
  /** 逆扁平率 */
  readonly D_INV_F: number = (298.257222101)
  /** 座標系の原点における縮尺係数 */
  readonly D_M0: number = (0.9999)

  constructor() {
    const oBasePointLon = environment.setting.wgs2Htz.oBasePointLon;
    const oBasePointLat = environment.setting.wgs2Htz.oBasePointLat;
    this.oBasePointLonRad = Math.PI * oBasePointLon / 180.0;
    this.oBasePointLatRad = Math.PI * oBasePointLat / 180.0;

    this.dC = this.D_A / (1.0 - this.D_F);    // 極での極率半径
    this.dE1 = Math.sqrt(2.0 * this.D_F - Math.pow(this.D_F, 2.0));  // 第一離心率
    this.dE2 = Math.sqrt(2.0 * this.D_INV_F - 1.0) / (this.D_INV_F - 1.0);    // 第二離心率

    const A: number = 1.0 + (3.0 / 4.0) * Math.pow(this.dE1, 2.0) + (45.0 / 64.0) * Math.pow(this.dE1, 4.0) + (175.0 / 256.0) * Math.pow(this.dE1, 6.0) + (11025.0 / 16384.0) * Math.pow(this.dE1, 8.0) + (43659.0 / 65536.0) * Math.pow(this.dE1, 10.0) + (693693.0 / 1048576.0) * Math.pow(this.dE1, 12.0) + (19324305.0 / 29360128.0) * Math.pow(this.dE1, 14.0) + (4927697775.0 / 7516192768.0) * Math.pow(this.dE1, 16.0);
    const B: number = (3.0 / 4.0) * Math.pow(this.dE1, 2.0) + (15.0 / 16.0) * Math.pow(this.dE1, 4.0) + (525.0 / 512.0) * Math.pow(this.dE1, 6.0) + (2205.0 / 2048.0) * Math.pow(this.dE1, 8.0) + (72765.0 / 65536.0) * Math.pow(this.dE1, 10.0) + (297297.0 / 262144.0) * Math.pow(this.dE1, 12.0) + (135270135.0 / 117440512.0) * Math.pow(this.dE1, 14.0) + (547521975.0 / 469762048.0) * Math.pow(this.dE1, 16.0);
    const C: number = (15.0 / 64.0) * Math.pow(this.dE1, 4.0) + (105.0 / 256.0) * Math.pow(this.dE1, 6.0) + (2205.0 / 4096.0) * Math.pow(this.dE1, 8.0) + (10395.0 / 16384.0) * Math.pow(this.dE1, 10.0) + (1486485.0 / 2097152.0) * Math.pow(this.dE1, 12.0) + (45090045.0 / 58720256.0) * Math.pow(this.dE1, 14.0) + (766530765.0 / 939524096.0) * Math.pow(this.dE1, 16.0);
    const D: number = (35.0 / 512.0) * Math.pow(this.dE1, 6.0) + (315.0 / 2048.0) * Math.pow(this.dE1, 8.0) + (31185.0 / 131072.0) * Math.pow(this.dE1, 10.0) + (165165.0 / 524288.0) * Math.pow(this.dE1, 12.0) + (45090045.0 / 117440512.0) * Math.pow(this.dE1, 14.0) + (209053845.0 / 469762048.0) * Math.pow(this.dE1, 16.0);
    const E: number = (315.0 / 16384.0) * Math.pow(this.dE1, 8.0) + (3465.0 / 65536.0) * Math.pow(this.dE1, 10.0) + (99099.0 / 1048576.0) * Math.pow(this.dE1, 12.0) + (4099095.0 / 29360128.0) * Math.pow(this.dE1, 14.0) + (348423075.0 / 1879048192.0) * Math.pow(this.dE1, 16.0);
    const F: number = (693.0 / 131072.0) * Math.pow(this.dE1, 10.0) + (9009.0 / 524288.0) * Math.pow(this.dE1, 12.0) + (4099095.0 / 117440512.0) * Math.pow(this.dE1, 14.0) + (26801775.0 / 469762048.0) * Math.pow(this.dE1, 16.0);
    const G: number = (3003.0 / 2097152.0) * Math.pow(this.dE1, 12.0) + (315315.0 / 58720256.0) * Math.pow(this.dE1, 14.0) + (11486475.0 / 939524096.0) * Math.pow(this.dE1, 16.0);
    const H: number = (45045.0 / 117440512.0) * Math.pow(this.dE1, 14.0) + (765765.0 / 469762048.0) * Math.pow(this.dE1, 16.0);
    const I: number = (765765.0 / 7516192768.0) * Math.pow(this.dE1, 16.0);


    this.dB1 = this.D_A * (1.0 - Math.pow(this.dE1, 2.0)) * A;
    this.dB2 = this.D_A * (1.0 - Math.pow(this.dE1, 2.0)) * (-B / 2.0);
    this.dB3 = this.D_A * (1.0 - Math.pow(this.dE1, 2.0)) * (C / 4.0);
    this.dB4 = this.D_A * (1.0 - Math.pow(this.dE1, 2.0)) * (-D / 6.0);
    this.dB5 = this.D_A * (1.0 - Math.pow(this.dE1, 2.0)) * (E / 8.0);
    this.dB6 = this.D_A * (1.0 - Math.pow(this.dE1, 2.0)) * (-F / 10.0);
    this.dB7 = this.D_A * (1.0 - Math.pow(this.dE1, 2.0)) * (G / 12.0);
    this.dB8 = this.D_A * (1.0 - Math.pow(this.dE1, 2.0)) * (-H / 14.0);
    this.dB9 = this.D_A * (1.0 - Math.pow(this.dE1, 2.0)) * (I / 16.0);
  }

  /** 緯度経度から平面直角座標への変換 */
  convertWgs2Htz(lat: number, lng: number): { outX: number, outY: number } {
    const psi: number = Math.PI * lat / 180.0;
    const lambda: number = Math.PI * lng / 180.0;

    const psi0: number = this.oBasePointLatRad;
    const lambda0: number = this.oBasePointLonRad;

    const Dlambda: number = lambda - lambda0; // 経度-座標系の原点の経度
    const t: number = Math.tan(psi);

    // 計算で求めるパラメータ
    const V = Math.sqrt(1.0 + Math.pow(this.dE2, 2.0) * Math.pow(Math.cos(psi), 2.0));	// （途中計算用）
    const N = this.dC / V;	// 卯酉線曲率半径
    const et2 = Math.pow(this.dE2, 2.0) * Math.pow(Math.cos(psi), 2.0);	//η２乗
    const S0 = this.dB1 * psi0 + this.dB2 * Math.sin(2 * psi0) + this.dB3 * Math.sin(4 * psi0) + this.dB4 * Math.sin(6 * psi0) + this.dB5 * Math.sin(8 * psi0) + this.dB6 * Math.sin(10 * psi0) + this.dB7 * Math.sin(12 * psi0) + this.dB8 * Math.sin(14 * psi0) + this.dB9 * Math.sin(16 * psi0);
    const S = this.dB1 * psi + this.dB2 * Math.sin(2 * psi) + this.dB3 * Math.sin(4 * psi) + this.dB4 * Math.sin(6 * psi) + this.dB5 * Math.sin(8 * psi) + this.dB6 * Math.sin(10 * psi) + this.dB7 * Math.sin(12 * psi) + this.dB8 * Math.sin(14 * psi) + this.dB9 * Math.sin(16 * psi);


    //緯度経度から平面直角座標x,yを求める計算
    const ret: { outX: number, outY: number } =
    {
      //x座標
      outX:
        (
          (S - S0) + N / 2.0 * Math.pow(Math.cos(psi), 2.0) * t * Math.pow(Dlambda, 2.0)
          + N / 24.0 * Math.pow(Math.cos(psi), 4.0) * t * (5.0 - Math.pow(t, 2.0) + 9.0 * et2 + 4.0 * Math.pow(et2, 2.0)) * Math.pow(Dlambda, 4.0)
          - N / 720.0 * Math.pow(Math.cos(psi), 6.0) * t * (-61.0 + 58.0 * Math.pow(t, 2.0) - Math.pow(t, 4.0) - 270.0 * et2 + 330.0 * Math.pow(t, 2.0) * et2) * Math.pow(Dlambda, 6.0)
          - N / 40320.0 * Math.pow(Math.cos(psi), 8.0) * t * (-1385.0 + 3111.0 * Math.pow(t, 2.0) - 543.0 * Math.pow(t, 4.0) + Math.pow(t, 6.0)) * Math.pow(Dlambda, 8.0)
        ) * this.D_M0,

      //y座標
      outY:
        (
          N * Math.cos(psi) * Dlambda
          - N / 6.0 * Math.pow(Math.cos(psi), 3.0) * (-1.0 + Math.pow(t, 2.0) - et2) * Math.pow(Dlambda, 3.0)
          - N / 120.0 * Math.pow(Math.cos(psi), 5.0) * (-5.0 + 18.0 * Math.pow(t, 2.0) - Math.pow(t, 4.0) - 14.0 * et2 + 58.0 * Math.pow(t, 2.0) * et2) * Math.pow(Dlambda, 5.0)
          - N / 5040.0 * Math.pow(Math.cos(psi), 7.0) * (-61.0 + 479.0 * Math.pow(t, 2.0) - 179.0 * Math.pow(t, 4.0) + Math.pow(t, 6.0)) * Math.pow(Dlambda, 7.0)
        ) * this.D_M0
    };
    return ret;
  }
}
