import { Injectable } from '@angular/core';

import { MqttService } from './mqtt.service';

@Injectable({
  providedIn: 'root'
})
export class MqttPublishService {
  private mqttClient: MqttService;

  constructor() { }

  /**
   * 開始します.
   */
  async start() {
    this.mqttClient = new MqttService();
    this.mqttClient.setConnection();
  }

  /**
   * 停止します.
   */
  async stop() {
    if (this.mqttClient) {
      this.mqttClient.disconnect();
      this.mqttClient = null;
    }
  }

  /**
   * コネクションの状態を確認し、接続が切れている場合、再接続します.
   * 
   * @returns 接続状態
   */
  checkAndRetryConnect(): boolean {
    if (!this.mqttClient) {
      return false;
    }

    if (this.mqttClient.checkConnect()) {
      return true;
    }

    this.mqttClient.setConnection();

    return false;
  }

  /**
   * パブリッシュ用のMQTT クライアントを返却します.
   */
  getMqttClient(): MqttService {
    return this.mqttClient;
  }
}
