import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { DummyTelemetryData } from './models/DummyTelemetryData';

@Component({
  selector: 'app-modal-debug',
  templateUrl: './modal-debug.component.html',
  styleUrls: ['./modal-debug.component.scss'],
})
export class ModalDebugComponent implements OnInit {
  textDummyTelemetryData:string = "";

  constructor(
    private modalCtrl: ModalController
  ) { }

  ngOnInit() {}

  clickStart(){
    // 緯度,経度,スピード,方向の文字列データを変換
    let dummyTelemetryDataList:DummyTelemetryData[] = this.textDummyTelemetryData.split(/\r\n|\n/).map(line=>{
      if(line.trim() === ""){
        return null;
      }

      let split = line.split(",");
      let data:DummyTelemetryData = {
        lat : Number.parseFloat(split[0]), // 緯度
        lng : Number.parseFloat(split[1]), // 経度
        speed : Number.parseFloat(split[2]), // スピード
        direction : Number.parseFloat(split[3]) // 方向
      };
      return data;
    }).filter(data => data != null);
    this.modalCtrl.dismiss({"isExecute":true,"data":dummyTelemetryDataList});
  }

  clickCancel(){
    this.modalCtrl.dismiss({"isExecute":false,"data":null});
  }

}
