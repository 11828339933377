import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

import { RouteUpdateAccepted } from './models/route-update-accepted';
import { MqttPublishService } from './mqtt-publish.service';
import { MqttService } from './mqtt.service';

import { SharedSettingService } from '../shared-setting.service';

@Injectable({
  providedIn: 'root'
})
export class RouteUpdateAcceptedMqttService {
  private publishClient: MqttService;

  private topic: string;

  constructor(
    private mqttPublishService: MqttPublishService,
    private sharedSettingService: SharedSettingService
  ) { }

  /**
   * 開始します.
   */
  async start() {
    this.topic = this.sharedSettingService.getVehicle().vin + environment.setting.setRouteAcceptedTopic;
    this.publishClient = this.mqttPublishService.getMqttClient();
  }

  /**
   * 停止します.
   */
  async stop() {
    this.publishClient = null;
  }

  /**
   * トピックを取得します.
   */
  getTopic(): string {
    return this.topic;
  }

  /**
   * コネクションの状態を確認し、接続が切れている場合、再接続します.
   * 
   * @returns 接続状態
   */
  checkAndRetryConnect(): boolean {
    return true;
  }

  /**
   * メッセージを送信します.
   * 
   * @param message 送信メッセージ
   * @returns 送信結果
   */
  publishMessage(message: RouteUpdateAccepted): boolean {
    if (this.publishClient?.checkConnect()) {
      this.publishClient.sendTopic(JSON.stringify(message), this.topic);

      return true;
    }

    return false;
  }

  /**
   * メッセージを作成します.
   * 
   * @param status status
   * @param requestIdDeNA requestIdDeNA
   * @param carVinDeNA carVinDeNA
   * @param polylineDeNA polylineDeNA
   * @returns メッセージ
   */
  createMessage(status: number, requestIdDeNA: string, carVinDeNA: string, polylineDeNA: string): RouteUpdateAccepted {
    return {
      status: status,
      result: {
        vin: carVinDeNA,
        polyline: polylineDeNA
      },
      request: {
        request_id: requestIdDeNA,
        vin: carVinDeNA,
        polyline: polylineDeNA,
        access_token: ''
      }
    };
  }
}
